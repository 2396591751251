import React from "react"
import { graphql } from "gatsby"

// import app components
import { FlexibleContent } from "components"

export default function home(props) {
  const {
    data: {
      wpPage: {
        title,
        uri,
        acf: { modules }
      }
    }
  } = props

  return (
    <>
      {!!modules && (
        <FlexibleContent
          rows={modules}
          data={{
            title,
            uri
          }}
        />
      )}
    </>
  )
}

export const CollectionQuery = graphql`
  query DefaultTemplate($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      uri
      settings {
        showBottomBanner
      }
      acf {
        modules {
          ... on WpPage_Acf_Modules_Boats {
            __typename
            option
            boatType {
              databaseId
            }
            button {
              url
              title
              target
            }
          }
          ... on WpPage_Acf_Modules_News {
            __typename
          }
          ... on WpPage_Acf_Modules_VideoHero {
            __typename
            headline
            subline
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: AUTO
                    layout: FULL_WIDTH
                    quality: 90
                    placeholder: BLURRED
                  )
                }
              }
            }
            video {
              localFile {
                publicURL
              }
            }
          }
          ... on WpPage_Acf_Modules_Text {
            __typename
            text
            button {
              title
              url
              target
            }
            backgroundColor
          }
          ... on WpPage_Acf_Modules_Testimonials {
            __typename
            headline
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: AUTO
                    layout: FULL_WIDTH
                    quality: 90
                    placeholder: BLURRED
                  )
                }
              }
            }
            testimonials {
              name
              text
            }
          }
          ... on WpPage_Acf_Modules_Logos {
            __typename
            logos {
              link
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: AUTO
                      width: 220
                      quality: 90
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_Modules_Images {
            __typename
            headline
            images {
              headline
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: AUTO
                      layout: FULL_WIDTH
                      quality: 90
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_Modules_Hero {
            __typename
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: AUTO
                    layout: FULL_WIDTH
                    quality: 90
                    placeholder: BLURRED
                  )
                }
              }
            }
            headline
          }
          ... on WpPage_Acf_Modules_Contact {
            __typename
            address
            directions {
              title
              url
              target
            }
          }
          ... on WpPage_Acf_Modules_Banner {
            __typename
            variant
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: AUTO
                    layout: FULL_WIDTH
                    quality: 90
                    placeholder: BLURRED
                  )
                }
              }
            }
            headline
            text
            button {
              title
              url
              target
            }
          }
        }
      }
    }
  }
`
